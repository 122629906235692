<template>
	<main>
		<div class="services-area pt-100 pb-65">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 col-xs-12">
						<div class="row" v-for="tutes in this.Tutorial_Data_Array" :key="tutes.id" style="margin-bottom:50px;">
							<div class="col-md-4 col-xs-12">
								<a href="javascript:void(0)" @click="GetVideo(tutes.id)"><img :src="tutes.image" class="vedio-img img-fluid"/></a>
							</div>
							<div class="col-md-8">
								<div class="section-title pb-10">
									<h4><a href="javascript:void(0)" @click="GetVideo(tutes.id)">{{tutes.title}}</a></h4>
								</div>
								<div class="about-contents">
									<p>
										{{tutes.desc}}
									</p>
									<a v-if="tutes.id!=15" :href="tutes.pdf_link" class="download-btn">Download PDF</a>
								</div>
							</div>
						</div>

						<hr class="solid" />

						<div class="row">
							<div class="col-md-12">
								<nav class="pagination">
									<ul>
										<li><a v-on:click="prev()" class="button">Previous</a></li>
										<li v-for="p in pageCount" :key="p.id">
										<a v-if="pageNo == p" v-on:click="page(p)" class="button is-primary">{{p}}</a>
										<a v-else v-on:click="page(p)" class="button">{{p}}</a>
										</li>
										<li><a v-on:click="next()" class="button">Next page</a></li>
									</ul>
								</nav>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-xs-12 res-cls">
						<div class="servicee-sidebar mb-50">
							<div class="sidebar-link grey-bg">
								<h3>STUCK SOMEWHERE ?</h3>
								<div class="support_sidebar">
									<p>
										We are here to help you out. You can learn from our video
										tutorials, ask a question, submit a ticket or contact us
										directly.
									</p>
									<p>
										You can also opt to get personalized support through remote
										desktop software, however, we recommend to go through the
										video tutorials first, if those are of any benefit to you.
									</p>
								</div>

								<h3>GOT A QUESTION ?</h3>
								<div class="support_sidebar">
									<p>
										Ask us, We will definitely look into your case. You can also
										submit a support ticket and we will be right there to help
										you out at the earliest.
									</p>
								</div>

								<h3>CONTACT SUPPORT</h3>
								<ul>
									<li><b>We work</b></li>
									<li>Mon-Sat, 10:30 AM to 8:00 PM (IST)</li>
									<li>support@xtrm.co.in</li>
									<li>+91 2836 227096</li>
								</ul>

								<h3>CATEGORIES</h3>
								<ul>
									<li><a href="javascript:void(0)" @click="$router.push('/products')">Fleet Manager</a></li>
									<li><a href="javascript:void(0)" @click="$router.push('/products')">FreightMan</a></li>
									<li><a href="javascript:void(0)" @click="$router.push('/products')">General</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Vedio_Data from '../../assets/data/Tutoriales.json'
const Tutorial_Data = Vedio_Data.Tutoriales;
export default {
  name: 'support',
  data () {
    return {
		Tutorial_Data,
        Tutorial_Data_Array: [],
		pageNo: 1,
        pageSize: 4,
        pageCount: 0  
    }
  },
  created() {
	// this.Tutorial_Data_Array = Tutorial_Data;
	// console.log("Data Is : ", this.Tutorial_Data_Array);
  },
  mounted(){
	this.init();
	// this.Tutorial_Data_Array = Tutorial_Data;
  },
  methods: {
	init: function(){ 
      this.virtualService({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      });
    },
    page: function(pageNo) { 
     
     this.virtualService({
        pageNo: pageNo,
        pageSize: this.pageSize
      });
    },
    prev: function() {
      if(this.pageNo > 1){ 
        this.pageNo -= 1; 
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        });
      }
    },
    next: function() {
      if(this.pageNo < this.pageCount) {
        this.pageNo += 1;
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        });
      }
    },
    virtualDataFromDb: function() {
      this.Tutorial_Data_Array = Tutorial_Data;
	// console.log("this are the data for pagination", this.Tutorial_Data_Array)
      return this.Tutorial_Data_Array;
    },
    count: function(){
      return this.virtualDataFromDb().length;
    },
    queryFromVirtualDB: function(condition, startRow, endRow){
      var result = [];
      condition = {};
      var data = this.virtualDataFromDb();
      var count = this.count(condition);
      for(var i = startRow - 1; i < endRow; i++) {
        if(i < count){
          result.push(data[i]);
        }
      }
      return result;
    },
    virtualService: function(params){
      
      var condition = {};
      var pageNo = params.pageNo;
      var pageSize = params.pageSize;
      var pageCount = Math.ceil(this.count(condition) / pageSize);
				
      if (pageNo == 0) pageNo = 1;
      if (pageNo < 0) pageNo = pageCount;
      else if (pageNo > pageCount) pageNo = pageCount;
      var startRow = (pageNo - 1) * pageSize + 1;
      var endRow = startRow + pageSize - 1;
      var data = this.queryFromVirtualDB(condition, startRow, endRow);
      
      // set result
      this.Tutorial_Data_Array = data;
      this.pageNo = pageNo;
      this.pageCount = pageCount;
      
    },
	GetVideo(id){
		this.$router.push({ path: '/tutoriales/' + id })
	}
  }
};
</script>

<style>
.download-btn:hover{
   color: #ffffff !important;
}
.is-primary{
  background-color: #d33327 !important;
  color: #ffffff !important;
}
.pagination ul > li{
  cursor: pointer;
  margin-right: 5px !important;
}
.pagination ul > li:hover{
  color: #ffffff !important;
}
@media(max-width:767px){
	.res-cls{
		display: none;
	}
	.section-title{
		margin-top: 15px;
	}
	.pagination ul > li > a{
		padding: 6px 12px;
	}
	.vedio-img{
		width:100%;
		height: 100%;
	}
}
@media(max-width:768px){
	.res-cls{
		display: none;
	}
}
</style>
